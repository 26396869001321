/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.boxed-group {
  margin-bottom: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  background-color: #fff;
}

.boxed-group-centered {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
}

.boxed-group > h2 {
  line-height: 24px;
  padding: 16px 20px 0;
}

.boxed-group > h2.boxed-title {
  padding: 0 0 8px;
  margin: 0;
  font-weight: 600;
}

.boxed-group hr {
  height: 0;
  border-top: 1px solid #efefef;
  margin: 16px -20px;
}

.boxed-group-header {
  position: relative;
  z-index: 10;
  padding: 16px 20px 0;
}

.boxed-group-header > h2 {
  display: inline-block;
  vertical-align: middle;
  line-height: 24px;
}

.boxed-group-actions {
  position: relative;
  z-index: 12;
  float: right;
  margin-top: 16px;
  margin-right: 20px;
}

.boxed-group-inner {
  padding: 16px 20px;
}

.boxed-group-inner:empty {
  padding-top: 0;
}

.boxed-group-list {
  margin-top: -var(--gridSize);
  margin-bottom: -var(--gridSize);
}

.boxed-group-list > li {
  margin-left: -20px;
  margin-right: -20px;
  padding: 8px 20px;
}

.boxed-group-accordion {
  margin-bottom: 8px;
  transition: border-color 0.3s ease;
}

.boxed-group-accordion:not(.no-hover):hover {
  border-color: #4b9fd5;
}

.boxed-group-accordion:not(.no-hover):hover .boxed-group-accordion-title {
  color: #4b9fd5;
}

.boxed-group-accordion .boxed-group-header {
  cursor: pointer;
  padding-bottom: 16px;
}

.boxed-group-accordion.not-clickable .boxed-group-header {
  cursor: default;
}

.boxed-group-accordion.not-clickable .boxed-group-accordion-title > svg {
  display: none;
}

.boxed-group-accordion-alert.boxed-group-accordion-alert {
  vertical-align: middle;
  margin-bottom: -6px;
  margin-top: -6px;
}

.boxed-group-accordion .boxed-group-inner {
  padding-top: 0;
}

.boxed-group-accordion-title {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  font-weight: bold;
  transition: color 0.3s ease;
}
