/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.shortcut-button {
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 21px;
  padding: 0 4px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-image: linear-gradient(to bottom, #f5f5f5, #eee);
  box-shadow: inset 0 1px 0 #fff, 0 1px 0 #ccc;
  color: #656565;
  font-size: 11px;
  text-align: center;
}

.shortcut-button-small {
  min-width: 16px;
  height: 16px;
  line-height: 14px;
  margin-left: 4px;
  margin-right: 4px;
}

.shortcut-button-tiny {
  width: 14px;
  min-width: auto;
  padding: 0;
  height: 14px;
  line-height: inherit;
  font-size: 6px;
}

.page-shortcuts-tooltip {
  line-height: 12px;
}

.identity-provider {
  display: inline-block;
  line-height: 14px;
  padding: 2px 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #236a97;
  font-size: 12px;
  color: #fff;
}

.analysis-version {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  padding: 0 8px;
  background-color: #4b9fd5;
  border-radius: 2px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  white-space: nowrap;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0;
}
